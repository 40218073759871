import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Seo from "../components/seo"
import {
  homeIntroText,
  homeTitle,
  homeHolder,
} from "../styles/PageStyles.module.scss"
import { socialsHolder } from "../styles/MainNav.module.scss"
import Instagram from "../images/svg/IG.svg"
import Twitter from "../images/svg/Twitter.svg"
import Apple from "../images/svg/Apple.svg"
import Spotify from "../images/svg/Spotify.svg"
import Bandcamp from "../images/svg/Bandcamp.svg"

const IndexPage = () => (
  <article className={homeHolder}>
    <Seo title="Welcome to the official website of Dot Allison" />
    <section>
      <StaticImage
        src="../images/Long Exposure Panorama Home.jpg"
        alt="Long Exposure"
        placeholder="blurred"
        layout="constrained"
        width={893}
        height={462}
      />
      <div className={homeIntroText}>
        <h2 className={homeTitle}>Heart Shaped Scars</h2>
        <p>
          <a
            href="https://sarecordings.com/format/724541-heart-shaped-scars"
            target="_blank"
            rel="noreferrer"
          >
            The new album. Out Now.
          </a>
        </p>
        <ul className={socialsHolder}>
          <li>
            <a
              className="socialLink"
              href="https://dotallison.bandcamp.com/"
              target="_blank"
              rel="noreferrer"
              alt="Dot's music on Bandcamp"
            >
              <div className="socialIcon">
                <Bandcamp />
              </div>
              <div className="socialDescription">
                <span>Dot's music on Bandcamp</span>
              </div>
            </a>
          </li>
          <li>
            <a
              className="socialLink"
              href="https://www.instagram.com/deetiebelle"
              target="_blank"
              rel="noreferrer"
              alt="Follow Dot on Instagram"
            >
              <div className="socialIcon">
                <Instagram />
              </div>
              <div className="socialDescription">
                <span>Follow Dot on Instagram</span>
              </div>
            </a>
          </li>
          <li>
            <a
              className="socialLink"
              href="https://twitter.com/DotAllisonmusic"
              target="_blank"
              rel="noreferrer"
              alt="Follow Dot on Twitter"
            >
              <div className="socialIcon">
                <Twitter />
              </div>
              <div className="socialDescription">
                <span>Follow Dot on Twitter</span>
              </div>
            </a>
          </li>
          <li>
            <a
              className="socialLink"
              href="https://open.spotify.com/artist/69IZoi4l9e7KcUpbgP9K7e?si=58ZbCiNXSfS87lDrD-GdkA"
              target="_blank"
              rel="noreferrer"
              alt="Dot Allison on Spotify"
            >
              <div className="socialIcon">
                <Spotify />
              </div>
              <div className="socialDescription">
                <span>Dot Allison on Spotify</span>
              </div>
            </a>
          </li>
          <li>
            <a
              className="socialLink"
              href="https://music.apple.com/us/artist/dot-allison/460529"
              target="_blank"
              rel="noreferrer"
              alt="Dot Allison on Apple Music"
            >
              <div className="socialIcon">
                <Apple />
              </div>
              <div className="socialDescription">
                <span>Dot Allison on Apple Music</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </section>
  </article>
)

export default IndexPage
